<template>
  <section class="inside-section">
    <div>
      <h4>{{ title }}</h4>
      <ul class="breadcrumb">
        <li><router-link to="/dashboard">Dashboard</router-link></li>
        <li>{{ title }}</li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  name: "PageHeader",
  props: ["title", "addlink", "downloadlink"],
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.inside-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
h4 {
  font-size: $titlefont;
  margin-bottom: 10px;
}
.fonticon {
  color: $white !important;
  font-size: $normalfont;
}
.btns {
  display: flex;
  li {
    margin-left: 10px;
  }
}
ul {
  padding-left: 0;
  list-style: none;
  .btn-default {
    display: inline-block;
    background: $tagcolor;
    text-decoration: none;
    padding: 10px 20px;
    color: $white;
    border-radius: 3px;
    font-size: $normalfont;
    text-transform: uppercase;
    border-radius: 5px;
  }
}
.breadcrumb {
  display: flex;
  font-size: $normalfont;
  a {
    text-decoration: none;
    color: $dark;
    font-weight: 500;
  }
  li {
    position: relative;
    margin-right: 15px;
    &::after {
      content: "/";
      position: absolute;
      right: -10px;
    }
    &:first-of-type {
      margin-left: 0;
    }
    &:last-of-type {
      &::after {
        display: none;
      }
    }
  }
}
</style>
