<template>
  <section>
    <v-container fluid>
      <v-row>
        <v-col md="12">
          <ReviewHeader
            :title="headercontent.title"
            :addlink="headercontent.addlink"
          />
        </v-col>

        <v-col md="12">
          <v-overlay :value="isLoading" absolute>
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
          <v-simple-table class="table">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">S/N</th>
                  <th class="listing">Name</th>
                  <th class="listing">Rating</th>
                  <th class="listing">Title</th>
                  <th class="listing">Created At</th>
                  <th class="table-list">Review Description</th>

                  <th class="text-right">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in allReviews.results" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td class="listing">{{ item.fullName }}</td>
                  <td class="listing">{{ item.rating }}</td>
                  <td class="listing">{{ item.title }}</td>
                  <td class="listing">
                    {{ item.createdAt.slice(0, 10) }}
                  </td>
                  <td v-html="item.description" class="table-list"></td>

                  <td class="text-right icons">
                    <v-icon class="icons-action" @click="deleteReview(item._id)"
                      >mdi-delete</v-icon
                    >
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
      <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="3000">
        {{ snackbarText }}
      </v-snackbar>
    </v-container>
  </section>
</template>

<script>
import ReviewHeader from "@/components/dashboard/ReviewHeader";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Reviews",
  components: { ReviewHeader },
  data: () => ({
    headercontent: {
      title: "Review Listing",
      addlink: "/dashboard/video/add",
    },
    valid: false,
    title: "",

    status: true,
    limit: 10,
    page: 1,

    snackbar: false,
    snackbarText: "",
    snackbarColor: "success",
    isLoading: false,
  }),
  methods: {
    validate() {},
    filterVideos() {
      this.fetchVideo({
        status: true,
        subCategory: this.selectedSubCategory,
        limit: this.limit,
        page: this.page,
      });
    },
    async fetchReviews() {
      this.isLoading = true;
      try {
        await this.fetchReviewList({
          limit: this.limit,
          page: this.page,
        });
      } finally {
        this.isLoading = false;
      }
    },
    async deleteReview(id) {
      if (!id) {
        console.error("No Review ID provided for deletion");
        return;
      }

      if (confirm("Are you sure you want to delete this review?")) {
        this.isLoading = true;
        try {
          const success = await this.deleteReviewAction(id);
          await this.fetchReviewList({
            limit: this.limit,
            page: this.page,
          });
          if (success) {
            this.allReviews.results = this.allReviews.results.filter(
              (review) => review._id !== id
            );
            this.showSnackbar("Review deleted successfully", "success");
          } else {
            console.error("Failed to delete review");
            this.showSnackbar("Review deleted successfully", "success");
          }
        } catch (error) {
          console.error("Error deleting review:", error);
        } finally {
          this.isLoading = false;
        }
      }
    },
    showSnackbar(text, color = "success") {
      this.snackbarText = text;
      this.snackbarColor = color;
      this.snackbar = true;
    },

    ...mapActions(["fetchReviewList", "loadingReview", "deleteReviewAction"]),
  },
  created() {
    this.fetchReviewList({
      limit: this.limit,
      page: this.page,
    });
  },
  computed: {
    ...mapGetters(["allReviews", "isLoadingReview"]),
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.filtertitle {
  margin-bottom: 10px;
}
.title {
  font-size: 1.875rem !important;
  font-weight: bold;
  margin: 10px 0 0 10px;
}

.btn-dark {
  background: $tagcolor !important;
  color: $white;
  margin-top: -10px;
}
.table {
  box-shadow: 0px -1px 5px 2px rgba(0, 0, 0, 0.1);

  thead {
    border-bottom: 2px solid $menucolor;
    background: $tagcolor;
  }
  th {
    font-size: $normal_lfont !important;
    color: $white !important;
  }
}
.icons {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 2px;
}
.listing {
  width: 9% !important;
}
.table-list {
  width: 89%;
  padding: 10px 0 !important;
}
</style>
